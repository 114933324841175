#group-context-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

#group-context-menu.hidden {
  display: none;
}

#group-context-menu h1 {
  position: fixed;
  top: 0;
  line-height: 9.6rem;
  margin: 0;
  font-size: 1.6rem;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  width: calc(100% - 18rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#group-context-menu h1 img {
  vertical-align: middle;
  margin-right: 0.3rem;
}

/* Edit Group Form */
#edit-group-form {
  width: calc(100% - 4rem);
  max-width: 48rem;
  padding: 4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: #597285;
  border-radius: 0.5rem;
}

#edit-group-form.hidden {
  display: none;
}

#edit-group-title-input {
  height: 3.2rem;
  flex: 1;
  margin: 0;
  padding: 0 1rem;
  box-sizing: border-box;
}

#edit-group-save-button {
  height: 3.2rem;
  font-size: 1.5rem;
  margin: 0 0 0 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: #7f93a1;
}

#edit-group-save-button:hover,
#edit-group-save-button:active {
  background-color: #97aebf;
}

/* Remove Group Form */
#remove-group-form {
  width: 100%;
  text-align: center;
}

#remove-group-form.hidden {
  display: none;
}

#remove-group-button {
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.6rem;
  width: 60%;
  min-width: 15rem;
  height: 6rem;
  background-color: #f55;
}

@media only screen and (max-width: 959px) {
  #edit-group-form {
    flex-direction: column;
  }

  #edit-group-title-input {
    flex: none;
  }

  #edit-group-save-button {
    margin: 2rem auto 0 auto;
    width: 9rem;
  }
}
