.group {
  background: #5288af;
  border-radius: 0.5rem;
  margin: 2rem 5rem;
  min-height: 5rem;
  max-height: 3.2rem;
}

.group.open {
  min-height: 24.4rem;
  max-height: none;
}

.group:not(.open) .thing {
  display: none;
}

.group .bar {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  position: relative;
  height: 5rem;
  box-sizing: border-box;
}

.group .bar .title {
  flex: 1;
  color: white;
  line-height: 4rem;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.group .bar .overflow-button {
  width: 3.2rem;
  height: 3.2rem;
  margin: 0.4rem;
  padding: 0;
  background-image: url('/images/overflow-small.svg');
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 2.4rem;
  background-position: center;
  border: none;
  border-radius: 2.5rem;
}

.group .bar .overflow-button:hover {
  background-color: #78abce;
}

.group .bar .overflow-button:active {
  background-color: #7dafd2;
}

.group .bar .expand-button {
  background-image: url('/images/down-arrow.svg');
  background-size: 2.4rem 2.4rem;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 3.2rem;
  height: 3.2rem;
  margin: 0.4rem;
  background-color: transparent;
}

.group.open .bar .expand-button {
  transform: rotate(-180deg);
}

.group.open.drag-target {
  border: 0.2rem dashed white;
}

.drag-before::before {
  display: block;
  content: '';
  position: relative;
  border-top: 0.2rem dashed white;
  width: 100%;
  top: -1rem;
  left: 0;
  margin-bottom: -2px; /* Prevent it taking up space */
}

.drag-after::after {
  display: block;
  content: '';
  position: relative;
  border-top: 0.2rem dashed white;
  width: 100%;
  bottom: -1rem;
  left: 0;
  margin-bottom: -2px; /* Prevent it taking up space */
}

.group.open .bar:last-child {
  margin-bottom: 19.2rem; /* Fill up an empty group */
}

.group-overflow-menu {
  position: absolute;
  top: 3.8rem;
  right: -0.25rem;
  background-color: #48779a;
  color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  transform: scale(1);
  transition: transform 0.25s ease;
  transform-origin: top right;
  z-index: 100;
  text-align: left;
}
