/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

#things-view {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  padding: 7.6rem 0;
}

#things-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
}

#things {
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  margin: auto;
  min-height: 5rem;
  width: calc(100% - 10rem);
}

#groups {
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
  width: 100%;
  padding-bottom: 7.6rem;
}

#groups.hidden,
#things.single-thing + #groups {
  display: none;
}

#add-button {
  bottom: 2rem;
  right: 2rem;
  background: no-repeat center/100% url('/images/add.svg');
}

/* Add Thing Menu */
#add-thing-menu {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  margin-right: 0;
}

#add-thing-back-button {
  background-color: transparent;
}

[draggable] {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: grab;
}

.drag-target {
  border: 0.2rem dashed white;
}
